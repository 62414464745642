<template>
  <img :src="src" :class="props.htmlClassAttribute" />
</template>

<script>
/**
 * @module
 * @vue-props {String} className
 * @vue-props {String} htmlClassAttribute - img要素に付与するクラス属性
 */
export default defineNuxtComponent({
  name: "jpblIconClassImg",
});
</script>

<script setup>
const config = useRuntimeConfig();

const iconClassAImg = config.public.SNS_PATH + "sp/img/2018/icon_class_a.png";
const iconClassBImg = config.public.SNS_PATH + "sp/img/2018/icon_class_b.png";
const iconClassCImg = config.public.SNS_PATH + "sp/img/2018/icon_class_c.png";
const iconClassDImg = config.public.SNS_PATH + "sp/img/2018/icon_class_d.png";
const iconClassEImg = config.public.SNS_PATH + "sp/img/2018/icon_class_e.png";
const iconClassFImg = config.public.SNS_PATH + "sp/img/2018/icon_class_f.png";
const iconClassNonImg = config.public.SNS_PATH + "sp/img/2018/icon_class_non.png";
const iconClassSImg = config.public.SNS_PATH + "sp/img/2018/icon_class_s.png";
const iconClassSoonImg = config.public.SNS_PATH + "sp/img/2018/icon_class_soon.png";
const iconClassSSImg = config.public.SNS_PATH + "sp/img/2018/icon_class_ss.png";

const imgMap = {
  a: iconClassAImg,
  b: iconClassBImg,
  c: iconClassCImg,
  d: iconClassDImg,
  e: iconClassEImg,
  f: iconClassFImg,
  non: iconClassNonImg,
  s: iconClassSImg,
  soon: iconClassSoonImg,
  ss: iconClassSSImg,
};

const props = defineProps({
  className: {
    type: String,
    required: true,
  },
  htmlClassAttribute: {
    type: String,
    default: "",
  },
});

const src = imgMap[props.className];
</script>

<style lang="scss" scoped></style>
